import React from "react"
import Navigation from "../Navigation/Navigation"
import "./GenerateBlog.css"

class GenerateBlog extends React.Component {
    

    render() {
        return (
            <div className="generate-blog-main">
                <Navigation/>
                generate blog
                <div id="editor-container">

                </div>

            </div>
        )
    }
}
export default GenerateBlog