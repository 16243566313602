import "../src/style.css"
import React from "react"
import Landing from "./Components/Landing/Landing.js"
import PersonalProjects from "./Components/PersonalProjects/PersonalProjects.js"
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import AboutPage from "./Components/AboutPage/AboutPage"
import Blog from "./Components/Blog/Blog"
import GenerateBlog from "./Components/Blog/GenerateBlog"

function App() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          {/* <Route path="/projects" element={<PersonalProjects />} /> */}
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/blog" element={<Blog/>}/>
          <Route path="/bx003zahdmin" element={<GenerateBlog/>}/>
        </Routes> 
      </BrowserRouter>
    )
  }
  
  export default App