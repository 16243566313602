import React from 'react'
import "./landing.css"
import InfoCard from "../InfoCard/InfoCard"
// import boulderPic from "../Assets/Images/landing-bg-desktop.jpeg"
import { useLocation } from 'react-router-dom';
import Navigation from '../Navigation/Navigation'

class Landing extends React.Component {
    constructor(props) {
        super()
        // console.log(props.goToNextPage)
    }

    render() {
        return (
            <div className='landing-main'>
                
                <InfoCard goToNextPage={this.props.goToNextPage}/>
                <div className="landing-image"><Navigation/></div>
            </div>
        )
    }
}

export default Landing
