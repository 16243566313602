import React from "react"
import "./infoCard.css"
import { Link } from "react-router-dom"

class InfoCard extends React.Component {
    constructor(props) {
        super()
        // console.log(props.goToNextPage)
    }

    render() {
        return (
            <div className="infoCard-main">
                <div className="infoCard-modal-img">
                    <span className="about-me">
                        <p className="about-me phrase-one">Hello, Thanks for stopping by! This site is where i showcase things im interested in and projects im working on.</p>
                        {/* <span className="infoCard-btns">
                            <a href="https://www.github.com/chakane3"><button className="about-me github-btn" > github </button></a>
                            <Link to="/projects"><button className="about-me nextPage" onClick={this.props.goToNextPage}>continue</button></Link>
                        </span> */}
                    </span>
                </div>            
            </div>
        )
    }
}
export default InfoCard
