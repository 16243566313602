import React from "react"
import Navigation from "../Navigation/Navigation"
import "./Blog.css"
class Blog extends React.Component {
    render() {
        return (
            <div className="blog-main">
                <Navigation/>
            </div>
        )
    }
}
export default Blog