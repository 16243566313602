import React from "react"
import './Navigation.css'
import { Link } from "react-router-dom"
// import MobileDropdown from "../MobileDropdown/MobileDropdown"
class Navigation extends React.Component {
    render() {
        return (
            <div>
                <nav className="navigation-main">
                <Link to="/"><button className="home" onClick={this.props.goToNextPage}>Home</button></Link>
                <Link to="/about"><button className="about" onClick={this.props.goToNextPage}>About</button></Link>
                {/* <Link to="/blog"><button className="blog" onClick={this.props.goToNextPage}>Blog</button></Link> */}
                {/* <Link to="/projects"><button className="projects" onClick={this.props.goToNextPage}>Projects</button></Link> */}
                <button className="github" ><a href="https://www.github.com/chakane3">Github</a></button>
                </nav>
            </div>
        )
    }
}
export default Navigation