import React from "react"
import './AboutPage.css'
import Navigation from "../Navigation/Navigation"
class AboutPage extends React.Component {

    render() {
        return (
            <div>
                <Navigation/>
                <div className="about-me-main-container">
                    <div className="name-section">
                        <span className="name-section name">Chakane Shegog</span>
                        <div className="about-side1">
                            Currently learning how to write better code, <br></br>
                            watch more televesion, <br></br>
                            and spend more time on my guitar. <br></br>
                        </div>
                    </div>
                    <div className="about-me-contact">
                        
                    </div>

                </div>
            </div>
        )
    }
}
export default AboutPage