import React from "react"
import "./PersonalProjects.css"
import ProjectsLabel from "./ProjectsLabel/ProjectsLabel.js"
import Navigation from "../Navigation/Navigation"
import ConvertTimezone from "../PersonalProjects/ProjectsCollection/ConvertTimezone"
class PersonalProjects extends React.Component {
    constructor(props) {
        super()
    }
    

    render() {
        return(

                <div>
                    <div className="projects-main">
                        <Navigation/>
                        <div className="proj-section1">
                            <div className="timezone-converter">
                                <ConvertTimezone />
                            </div>
                        </div>
                        <div className="proj-section2">
                            .
                        </div>
                        <div className="proj-section3">
                            .
                        </div>
                        <div className="proj-section4">
                            .
                        </div>

                    </div>
                </div>
            
        )
 
    }
}

export default PersonalProjects