import React, { useState, useEffect, useRef } from "react";
import "./ConvertTimezone.css";
import { format, utcToZonedTime } from 'date-fns-tz';
import timeZones from './timezones.json';

export default function ConvertTimezone() {
  const [selectedTimezone, setSelectedTimezone] = useState('America/New_York');
  const [currentTime, setCurrentTime] = useState('');

  const blinkRef = useRef(null);
  const blinkIntervalRef = useRef(null);

  useEffect(() => {
    const updateTime = () => {
      const date = new Date();
      const tzDate = utcToZonedTime(date, selectedTimezone);
      setCurrentTime(format(tzDate, 'HH:mm:ss', { timeZone: selectedTimezone }));
    };

    updateTime(); // Initial update

    const intervalId = setInterval(updateTime, 1000); // Update every second

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedTimezone]);

  useEffect(() => {
    if (blinkRef.current) {
      blinkIntervalRef.current = setInterval(() => {
        blinkRef.current.style.visibility = (blinkRef.current.style.visibility === 'hidden') ? 'visible' : 'hidden';
      }, 500);
    }

    return () => {
      clearInterval(blinkIntervalRef.current);
    };
  }, []);

  const handleTimezoneChange = (e) => {
    setSelectedTimezone(e.target.value);
  }

  return (
    <div className="ConvertTimezone">
      <select value={selectedTimezone} onChange={handleTimezoneChange}>
        {timeZones.map((tz, index) => (
          <option key={index} value={tz}>{tz}</option>
        ))}
      </select>
      <p>
        Current time in {selectedTimezone}:
        <span ref={blinkRef}>{currentTime}</span>
      </p>
    </div>
  );
}
