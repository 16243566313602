import React from "react"
import "./ProjectsLabel.css"
class LandingLabel extends React.Component {
    constructor() {
        super()
    }
    render() {
        return (
            <div className="projectCard-left">
            
                <div className="projectsCard-label">
                    
                </div>
            
            
            </div>
        )
    }
}

export default LandingLabel